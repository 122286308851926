import * as APIt from '../API';
import { Alert, Button, Icon, Modal, ProgressBar, ProgressBarProps, Spinner } from '@amzn/awsui-components-react';
import { DEFAULT_LC_BUILDER, ILanguage } from "../constants/Constants";
import React, { useEffect, useState } from 'react';
import { createUserPrefs, fetchUserPrefs, updateUserPrefs } from '../utils/UserPrefsUtils';
import {
  selectPhySecMember,
  selectPhySecRegions,
  selectSideTeam,
  selectSigAdmin,
  selectUserSites,
  setUserPrefs,
  setUserSites
} from '../stores/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import App from './App';
import SelectSite from './navigation/SelectSite';
import { debug } from '../utils/commonUtils';
import { fetchUserSites } from '../utils/UserSitesUtils';
import i18n from "src/i18n";
import { useBundle } from '@amzn/react-arb-tools';
import { useLocalizationContext } from "@amzn/react-arb-tools";

enum initialStepResults {
  failure = 'FAILURE',
  success = 'SUCCESS'
};
interface initStepInterface {
  stepNum: number;
  stepDesc: string;
  resultDesc: string;
  result: initialStepResults | null;
};

export default function AuthorizeUser(props: { username: string, employeeId: number }) {
  debug(`AuthorizeUser(): props is ${JSON.stringify(props)}`);

  const stage = 'prod';

  let initStepsTotal = 2;

  const [bundle, isBundleLoading] = useBundle('components.AuthorizeUser');

  const dispatch = useDispatch();

  const phySecMember = useSelector(selectPhySecMember);
  const phySecRegions = useSelector(selectPhySecRegions);
  const sideTeam = useSelector(selectSideTeam);
  const sigAdmin = useSelector(selectSigAdmin);
  const userSites = useSelector(selectUserSites);

  const [initSteps, setInitSteps] = useState<initStepInterface[]>([]);
  const [siteSelected, setSiteSelected] = useState<boolean>();
  const [showRetry, setShowRetry] = useState<boolean>(false);
  const [showMustSelectSiteAlert, setShowMustSelectSiteAlert] = useState<boolean>(false);
  const [siteSelectVisible, setSiteSelectVisible] = useState<boolean>(false);
  const [loadingSites, setLoadingSites] = useState<boolean>(false);
  const [progressBar, setProgressBar] = useState<ProgressBarProps>(
    {
      status: 'in-progress',
      value: (1 / initStepsTotal) * 100,
      description: 'Authenticated User',
      label: ''
    }
  );

  const { setLocalizationContext } = useLocalizationContext();

  const getEnvironment = () => {
    switch (stage.toLowerCase()) {
      case 'prod':
        return '';
      default:
        return `Environment: ${stage}`;
    }
  };

  const loadUserSites = async (username: string, employeeId: number) => {
    debug(`AuthorizeUser(): loadUserSites(${username} employeeId is ${employeeId})`);
    const maxAttempts = 2;
    let userSites: APIt.UserPrefSite[] = [];
    let message = '';
    for (let attemptNum = 1; attemptNum <= maxAttempts; attemptNum++) {
      try {
        debug(`AuthorizeUser(): loadUserSites() attempt ${attemptNum}`);
        userSites = await fetchUserSites(
          {
            loginUsername: username,
            phySecMember: phySecMember,
            phySecRegions: phySecRegions,
            sideTeam: sideTeam,
            sigAdmin: sigAdmin,
            facmanParams: {
              sitename: '',
              groupname: 'MASKING APPROVERS',
              username: username,
              employeeId: employeeId
            }
          });
        debug(`AuthorizeUser(): loadUserSites() userSites is ${JSON.stringify(userSites)}`);
        break;
      } catch (error: any) {
        if (typeof error === 'object' && Object.keys(error as object).includes('errors') && Array.isArray(error['errors']))
          message = error.errors[0].message;
        if (attemptNum >= maxAttempts) {
          if (message) throw (message);
          throw (error);
        }
      }
    }
    if (userSites) {
      dispatch(setUserSites(userSites));
      const userPrefs = await fetchUserPrefs(username);
      if (userPrefs) {
        userPrefs.sites = userSites;
        await updateUserPrefs(userPrefs);
        dispatch(setUserPrefs(userPrefs));
      }
    }
    return userSites;
  };

  const initialize = async () => {
    debug(`AuthorizeUser(): initialize()`);

    let success = true;
    let localInitSteps: initStepInterface[] = [
      {
        stepNum: 1,
        stepDesc: bundle.getMessage('authenticate-user'),
        resultDesc: bundle.formatMessage('authenticated', { username: props.username }),
        result: initialStepResults.success
      }
    ];
    localInitSteps.push({
      stepNum: 2,
      stepDesc: bundle.getMessage('fetch-user-preferences'),
      result: null,
      resultDesc: ''
    });
    setInitSteps(localInitSteps);
    setProgressBar({
      status: 'in-progress',
      value: ((1 / initStepsTotal) * 100),
      description: bundle.getMessage('fetching-user-preferences'),
    });
    const userPrefs = await fetchUserPrefs(props.username);
    if (userPrefs?.site) {
      const darkMode = JSON.parse(userPrefs.site)['darkMode'];
      if (darkMode) {
        document.body.classList.add('awsui-polaris-dark-mode');
      }

      const languageStr = JSON.parse(userPrefs.site)['language'];
      if (languageStr) {
        try {
          const language: ILanguage = JSON.parse(languageStr);
		      i18n.changeLanguage(language.id);
		      setLocalizationContext(DEFAULT_LC_BUILDER.withLocale(language.id).build());
        } catch(error) {
          debug(`AuthorizeUser(): fetchUserPrefs() error setting language for string ${languageStr}`);
          console.error(error);
        }
        debug(`AuthorizeUser(): fetchUserPrefs() language not found.`);
      }
    }
    debug(`AuthorizeUser(): intialize() userPrefs is ${JSON.stringify(userPrefs)}`);
    setProgressBar({
      status: 'in-progress',
      value: ((2 / initStepsTotal) * 100),
      description: bundle.getMessage('fetched-user-preferences')
    });
    let userSites: (APIt.UserPrefSite | null)[] = [];
    if (!userPrefs) {
      localInitSteps = [
        ...localInitSteps.filter(s => s.stepNum !== 2),
        { stepNum: 2, stepDesc: bundle.getMessage('fetch-user-preferences'), result: initialStepResults.failure, resultDesc: bundle.getMessage('user-preferences-not-found') }
      ];
      setInitSteps(localInitSteps);
    } else {
      const siteCount = userPrefs.sites ? userPrefs.sites.length : 0;
      localInitSteps = [
        ...localInitSteps.filter(s => s.stepNum !== 2),
        { stepNum: 2, stepDesc: bundle.getMessage('fetch-user-preferences'), result: initialStepResults.success, resultDesc: bundle.formatMessage('user-preferences-found', { siteCount }) }
      ];
      setInitSteps(localInitSteps);
      if (userPrefs.sites) userSites = userPrefs.sites || [];
      debug(`AuthorizeUser(): initialize() userSites is ${JSON.stringify(userSites)}`);
      if (userSites) dispatch(setUserSites(userSites));
      if (userPrefs) {
        userPrefs.sites = userSites;
        await updateUserPrefs(userPrefs);
        dispatch(setUserPrefs(userPrefs));
      }
    }
    if (!userPrefs || !userPrefs.sites || userPrefs.sites.length === 0) {
      setInitSteps(localInitSteps);
      localInitSteps = [
        ...localInitSteps,
        { stepNum: 3, stepDesc: bundle.getMessage('fetch-user-sites'), result: null, resultDesc: '' }
      ];
      setInitSteps(localInitSteps);
      initStepsTotal = initStepsTotal + 2;
      setProgressBar({
        status: 'in-progress',
        value: ((2 / initStepsTotal) * 100),
        description: bundle.getMessage('fetching-user-sites')
      });
      try {
        setLoadingSites(true);
        userSites = await loadUserSites(props.username, props.employeeId);
        setLoadingSites(false);
        debug(`AuthorizeUser(): userSites is ${JSON.stringify(userSites)}`);
        localInitSteps = [
          ...localInitSteps.filter(s => s.stepNum !== 3),
          { stepNum: 3, stepDesc: bundle.getMessage('fetch-user-sites'), result: initialStepResults.success, resultDesc: bundle.formatMessage('user-sites-found', { siteCount: userSites.length }) }
        ];
        setInitSteps(localInitSteps);
      } catch (error: any) {
        console.error(error);
        success = false;
        localInitSteps = [
          ...localInitSteps.filter(s => s.stepNum !== 3),
          { stepNum: 3, stepDesc: bundle.getMessage('fetch-user-sites'), result: initialStepResults.failure, resultDesc: bundle.getMessage('user-sites-error') }
        ];
        setInitSteps(localInitSteps);
        setShowRetry(true);
      }
      setProgressBar({
        status: 'in-progress',
        value: (3 / initStepsTotal) * 100,
        description: bundle.getMessage('fetched-user-sites')
      });
      localInitSteps = [
        ...localInitSteps,
        { stepNum: 4, stepDesc: bundle.getMessage('initilize-user-prefs'), result: null, resultDesc: '' }
      ];
      setInitSteps(localInitSteps);
      try {
        const createdUserPrefs = await createUserPrefs(
          {
            __typename: 'UserPrefs',
            username: props.username,
            inputs: {
              __typename: 'InputsPrefs',
              numRecordsPerPage: 50,
              savedSearches: []
            },
            outputs: {
              __typename: 'OutputsPrefs',
              numRecordsPerPage: 50,
              savedSearches: []
            },
            readersAlarms: {
              __typename: 'ReadersAlarmsPrefs',
              numRecordsPerPage: 50,
              savedSearches: []
            },
            readersModes: {
              __typename: 'ReadersModesPrefs',
              numRecordsPerPage: 50,
              savedSearches: []
            },
            schedules: {
              __typename: 'SchedulesPrefs',
              numRecordsPerPage: 50,
            },
            site: JSON.stringify({ darkMode: false }),
            sites: userSites
          }
        );
        debug(`AuthorizeUser(): initialize() createdUserPrefs is ${JSON.stringify(createdUserPrefs)}`);
        if (createdUserPrefs) dispatch(setUserPrefs(createdUserPrefs));
        localInitSteps = [
          ...localInitSteps.filter(s => s.stepNum !== 4),
          { stepNum: 4, stepDesc: bundle.getMessage('initilize-user-prefs'), result: initialStepResults.success, resultDesc: bundle.getMessage('user-preferences-initialized') }
        ];
        setInitSteps(localInitSteps);
      } catch (error) {
        console.error(error);
        success = false;
        localInitSteps = [
          ...localInitSteps.filter(s => s.stepNum !== 4),
          { stepNum: 4, stepDesc: bundle.getMessage('initilize-user-prefs'), result: initialStepResults.failure, resultDesc: bundle.getMessage('user-preferences-error') }
        ];
        setInitSteps(localInitSteps);
      }
    }
    setProgressBar({
      status: 'in-progress',
      value: (2 / initStepsTotal) * 100,
      description: bundle.getMessage('fetched-user-preferences')
    });
    setProgressBar({
      status: 'in-progress',
      value: 100,
      description: bundle.getMessage('complete')
    });
    if (success) setSiteSelectVisible(true);
  };

  useEffect(() => {
    if (!props.username) return;
    if (!bundle) return;
    initialize();
  }, [props.username, bundle]);

  const siteSelectedCallback = () => {
    setSiteSelected(true);
  };

  const refreshUserSites = async () => {
    debug(`AuthorizeUser(): refreshUserSites() props.username is ${props.username}`);
    setLoadingSites(true);
    try {
      await loadUserSites(props.username, props.employeeId);
    } catch (error) {
      debug(`AuthorizeUser(): refreshUserSites() error is ${error}`);
    }
    setLoadingSites(false);
  };

  const initializingOnDismissHandler = () => {
    setShowMustSelectSiteAlert(true);
  };

  const stepStatus = (result: initialStepResults | null) => {
    switch (result) {
      case null:
        return (
          <Spinner />
        );
      case initialStepResults.success:
        return (
          <Icon name='status-positive' size='normal'></Icon>
        );
      case initialStepResults.failure:
        return (
          <Icon name='status-negative' size='normal'></Icon>
        );
    }
  };

  let componentToRender;

  if (isBundleLoading) return <Spinner />;

  if (siteSelected) {
    componentToRender = (<App refreshUserSitesCallback={refreshUserSites} />)
  } else {
    componentToRender = (
      <div id='container'>
        <Modal visible={true} size='medium' header={`${bundle.getMessage('initializing')} Silencio`} onDismiss={initializingOnDismissHandler}>
          <ProgressBar
            status={progressBar.status}
            value={progressBar.value}
            description={progressBar.description}
            label={progressBar.label}
          />
          <table key='initStepsTable' style={{ width: '100%' }}>
            <tbody>
              <tr>
                <th key='th1' style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>{bundle.getMessage('step-number')} </th>
                <th key='th2' style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>{bundle.getMessage('description')}</th>
                <th key='th3' style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>{bundle.getMessage('status')}</th>
                <th key='th4' style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>{bundle.getMessage('result')}</th>
              </tr>
              {initSteps.map(s =>
                <tr key={s.stepNum}>
                  <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>{s.stepNum}</td>
                  <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>{s.stepDesc}</td>
                  <td style={{ border: '1px solid #dddddd', textAlign: 'center', padding: '8px' }}>{stepStatus(s.result)}</td>
                  <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>{s.resultDesc}</td>
                </tr>)
              }
            </tbody>
          </table>
          <p />
          <div hidden={!showRetry}>
            <Button onClick={initialize} iconName='refresh'>{bundle.getMessage('retry')}</Button>
          </div>
          <p />
          <div hidden={!siteSelectVisible}>
            <SelectSite
              refreshUserSitesCallback={refreshUserSites}
              selectedSite={userSites[0]}
              siteRequiredAlertVisible={false}
              siteSelectVisible={siteSelectVisible}
              setSiteSelectVisibleCallback={setSiteSelectVisible}
              siteSelectedCallback={siteSelectedCallback}
              loadingSites={loadingSites}
            >
            </SelectSite>
          </div>
          <p>{getEnvironment()}</p>
          <p />
          <Alert visible={showMustSelectSiteAlert} type='error' dismissible onDismiss={() => setShowMustSelectSiteAlert(false)}>
            {bundle.getMessage('select-site-continue')}
          </Alert>
        </Modal>
      </div>);
  }

  return componentToRender;
}
